/* General shimmer effect */
.shimmer {
  animation: shimmer 1.5s infinite;
  background: linear-gradient(to right, #f0f0f0 8%, #e0e0e0 18%, #f0f0f0 33%);
  background-size: 1200px 100%;
}

@keyframes shimmer {
  0% {
    background-position: -1200px 0;
  }
  100% {
    background-position: 1200px 0;
  }
}

/* Shimmer styles for product cards */
.shimmer-product-card {
  width: 280px;
  height: 340px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 10px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.shimmer-product-image {
  width: 100%;
  height: 150px;
  border-radius: 8px;
  background-color: #f0f0f0;
}

.shimmer-product-name,
.shimmer-product-price,
.shimmer-product-add-to-cart {
  width: 80%;
  height: 20px;
  border-radius: 4px;
  background-color: #f0f0f0;
}

.shimmer-product-name {
  width: 60%;
}

.shimmer-product-price {
  width: 40%;
}

.shimmer-product-add-to-cart {
  width: 50%;
  height: 30px;
}

/* Base Styling - Applies to both large and small screens */
.filterComponentContainer {
  background-color: #fff;
  color: black;
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1), inset 0 2px 5px rgba(0, 0, 0, 0.05);
  overflow-y: auto;
  padding: 20px;
  scrollbar-width: thin;
  scrollbar-color: #888 #f0f0f0;
  position: relative;
  left: 15px;
  transition: box-shadow 0.3s ease;
}

.filterComponentContainer h3 {
  margin-top: 0;
  font-size: 18px;
  font-weight: 600;
  color: black;
}

.filterComponentContainer hr {
  border: 1px solid #4caf50;
  margin: 10px 0 20px;
}

.filterComponentContainer input[type="checkbox"] {
  appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #4caf50;
  border-radius: 6px;
  margin-right: 10px;
  outline: none;
  cursor: pointer;
  transition: background-color 0.3s ease, border-color 0.3s ease;
  background-color: transparent;
  position: relative;
}

.filterComponentContainer input[type="checkbox"]:checked {
  background: #4caf50;
  border-color: #4caf50;
}

.filterComponentContainer input[type="checkbox"]:checked::after {
  content: "\2714";
  font-size: 14px;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.filterComponentContainer label {
  display: flex;
  align-items: center;
  font-size: 16px;
  margin-bottom: 12px;
  cursor: pointer;
  transition: color 0.3s ease, background-color 0.3s ease;
  padding: 5px;
  border-radius: 6px;
}

.filterComponentContainer label:hover {
  color: #fff;
  background-color: #4caf50;
}

.search-box {
  border: 1px solid #4caf50;
  padding: 12px;
  width: 100%;
  border-radius: 26px;
  margin-top: 12px;
  background-color: #fff;
  color: #333;
  font-size: 16px;
  outline: none;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.search-box:focus {
  border-color: #e91e63;
  box-shadow: 0 2px 8px rgba(233, 30, 99, 0.3);
  cursor: text;
}

.search-box::placeholder {
  color: #888;
}

.allProductsHeadingBox {
  font-size: 24px;
  margin-bottom: 36px;
  font-weight: 600;
  margin-top: 28px;
  color: #4caf50;
  text-align: center;
  /* margin-left: 79px; */
}

/* Grid for displaying products */
.mainBoxContainerAllProducts1 {
  /* display: grid; */
  padding-left: 78px;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.headingShopCenter{
  display: flex;
  justify-content: center;
  align-items: center;
}

.productCard2 {
  border: 1px solid #ccc;
  border-radius: 22px;
  padding: px;
  background-color: #fff;
  text-align: center;
  height: auto;
  position: relative;
}

.productCard:hover {
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.productImageContainer1 {
  position: relative;
  overflow: hidden;
}

.productImageContainer1 img {
  max-width: 180%;
  object-fit: cover;
  max-height: 300px;
  border-top-left-radius: 22px;
  border-top-right-radius: 22px;
  border-bottom-left-radius: -10px;
  border-bottom-right-radius: 0;
  transform: translateY(-10px) scale(0.8);
  left: 20px;
  position: relative;
}

.productNameContainer4 {
  font-weight: bold;
  font-size: 16px;
  margin-top: -20px;
  cursor: pointer;
}

.productNameContainer4 span {
  display: inline-block;
  transform: translateY(-15px);
}

.productPriceContainer4 {
  font-weight: 600;
  font-size: 14px;
  color: green;
  margin-top: -10px;
}

.productPriceContainer4 span {
  display: inline-block;
  transform: translateY(-2px);
}

.productAddToCartButton4 {
  margin-top: 20px;
  cursor: pointer;
  margin-left: 68px;
}

.productAddToCartButton4 button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.productAddToCartButton4 .cart {
  margin-left: 10px;
}

.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #4caf50;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button.loading {
  background-color: #999;
  cursor: not-allowed;
}

.suggestions-list {
  position: absolute;
  border: 1px solid #ddd;
  background-color: #fff;
  z-index: 1000;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  padding: 0;
  margin: 0;
  border-radius: 6px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.suggestion-item {
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.suggestion-item:hover,
.suggestion-item.highlighted {
  background-color: #4caf50;
  color: white;
}

/* Larger Screens */
@media only screen and (min-width: 1000px) {
  .shopByCategoryContainer {
    display: flex;
    flex-direction: row;
    gap: 30px;
    justify-content: center;
    align-items: center;
  }

  .filterComponentContainer {
    width: 26%;
    height: 350px;
    margin-top: 80px;
  }

  .productCardComponent {
    width: 74%;
    padding-bottom: 20px;
  }

  .mainBoxContainerAllProducts1 {
    grid-template-columns: repeat(3, 1fr);
  }
}

/* Smaller Screens */
@media screen and (max-width: 999px) {
  .shopByCategoryContainer {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  .filterComponentContainer {
    height: 350px;
    margin-top: 80px;
    width: 280px;
    left: 0;
  }

  .filterComponentContainer input[type="checkbox"] {
    width: 18px;
    height: 18px;
    border-radius: 4px;
  }

  .filterComponentContainer label {
    font-size: 14px;
  }

  .search-box {
    padding: 10px;
    border-radius: 20px;
  }

  .allProductsHeadingBox {
    font-size: 20px;
    margin-top: 24px;
  }

  .mainBoxContainerAllProducts1 {
    padding-right: 20px;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }

  .productImageContainer1 img {
    text-align: center;
    left: -6px;
    height: 300px;
  }

  .productAddToCartButton4 {
    margin-left: 40px;
  }
}

/* Extra small screens */
@media screen and (max-width: 470px) {
  .shopByCategoryContainer {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  .filterComponentContainer {
    height: 350px;
    margin-top: 80px;
    width: 280px;
    left: 0;
  }

  .filterComponentContainer input[type="checkbox"] {
    width: 18px;
    height: 18px;
    border-radius: 4px;
  }

  .filterComponentContainer label {
    font-size: 14px;
  }

  .search-box {
    padding: 10px;
    border-radius: 20px;
    padding-left: 2px;
    padding-right: 2px;
  }

  .allProductsHeadingBox {
    font-size: 20px;
    margin-top: 24px;
  }

  .mainBoxContainerAllProducts1 {
    grid-template-columns: repeat(1, 1fr);
    gap: 30px;
    margin-right: 40px;
  }

  .productImageContainer1 {
    height: 300px;
    left: 12px;
  }
}
