.footerMainContainer{
    background-color: rgb(235, 235, 235);
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.firstFrameContainer{
/* border: 2px solid red; */
    display: flex;
    gap: 30px;

}

.footerLeftContainer{
/* border: 2px solid green; */
    width: 70%;
    padding: 20px;
}

.footerLeftContainer span{
    color: rgb(110, 110, 110);
    font-size: 14px;
}

.footerRightContainer{
/* border: 2px solid blue; */
    width: 30%;
    padding: 20px;
}

.footerRightContainer ul li{
    color: rgb(110, 110, 110);
    font-size: 14px;
    cursor: pointer;
}

.secondFrameContainer{
    /* border: 2px solid rgb(209, 255, 57); */
    display: flex;
    background-color: rgb(189, 189, 189);
}

.copyrightFrameContainer{
    /* border: 2px solid palevioletred; */
    width: 80%;
    padding: 20px;
}

.copyrightFrameContainer span{
    color: rgb(63, 63, 63);
    font-size: 16px;
}

.followIconContainer{
    /* border: 2px solid brown; */
    width: 20%;
}

.followIconTextContainer span{
    color: rgb(110, 110, 110);
    font-size: 14px;
}

.followIconSvgContainer{
    /* border: 2px solid green; */
    display: flex;

}
.followIconSvgContainer svg{
    width: 25px;
    height: 25px;
    cursor: pointer;
}

@media screen and (max-width: 780px){

    .footerLeftContainer{
        /* border: 2px solid green; */
            width: 60%;
            padding: 20px;
        }
        

        
        .footerRightContainer{
        /* border: 2px solid blue; */
            width: 40%;
            padding: 20px;

        }

    .copyrightFrameContainer{
        /* border: 2px solid palevioletred; */
        width: 70%;
        padding: 20px;
    }
    .followIconContainer{
        /* border: 2px solid brown; */
        width: 30%;
    }
}