/* General shimmer effect */
.shimmer {
  animation: shimmer 1.5s infinite;
  background: linear-gradient(to right, #f0f0f0 8%, #e0e0e0 18%, #f0f0f0 33%);
  background-size: 1200px 100%;
}

@keyframes shimmer {
  0% {
    background-position: -1200px 0;
  }
  100% {
    background-position: 1200px 0;
  }
}

/* Shimmer styles for product cards */
.shimmer-product-card {
  width: 280px;
  height: 340px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 10px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.shimmer-product-image {
  width: 100%;
  height: 150px;
  border-radius: 8px;
  background-color: #f0f0f0;
}

.shimmer-product-name,
.shimmer-product-price,
.shimmer-product-add-to-cart {
  width: 80%;
  height: 20px;
  border-radius: 4px;
  background-color: #f0f0f0;
}

.shimmer-product-name {
  width: 60%;
}

.shimmer-product-price {
  width: 40%;
}

.shimmer-product-add-to-cart {
  width: 50%;
  height: 30px;
}
#giftingHeading {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 2.5em; 
    font-weight: bold;
    color: #4caf50; 
    text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.7); 

}

#giftingContainer {
    padding-bottom: 20px;
}

#mainBoxContainerAllProducts {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 24px;
}

[id^="productCard-"] {
    width: 280px;
    height: 380px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    border-radius: 22px;
    background-color: white;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
}

[id^="productCard-"]:hover {
    transform: scale(1.05); 
}

[id^="productImageContainer-"] {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    position: relative;
    overflow: hidden;
}

[id^="productImageContainer-"] img {
    width: 100%;
    height: 100%;
    margin-top: 20px;
    border-radius: 22px;
}

[id^="productNameContainer-"] {
    font-weight: bold;
    margin-top: 4px;
}

[id^="productPriceContainer-"] {
    font-weight: 600;
    color: green;
}

[id^="productAddToCartButton-"] #button {
    --background: #362A89;
    --text: #fff;
    --cart: #fff;
    --tick: var(--background);
    position: relative;
    margin-bottom: 10px;
    border: none;
    background: none;
    padding: 8px 28px;
    border-radius: 8px;
    cursor: pointer;
    text-align: center;
    min-width: 144px;
    color: var(--text);
    background: var(--background);
    transform: scale(var(--scale, 1));
    transition: transform .4s cubic-bezier(.36, 1.01, .32, 1.27);
}

[id^="productAddToCartButton-"] #button:active {
    --scale: .95;
}

[id^="productAddToCartButton-"] #button span {
    font-size: 14px;
    font-weight: 500;
    display: block;
    position: relative;
    padding-left: 24px;
    margin-left: -8px;
    line-height: 26px;
    transform: translateY(var(--span-y, 0));
    transition: transform .7s ease;
}

[id^="productAddToCartButton-"] #button .cart {
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -13px 0 0 -18px;
    transform-origin: 12px 23px;
    transform: translateX(-120px) rotate(-18deg);
}

[id^="productAddToCartButton-"] #button .cart svg {
    z-index: 1;
    width: 36px;
    height: 26px;
    display: block;
    fill: none;
    stroke: var(--cart);
    stroke-width: 2px;
    stroke-linecap: round;
    stroke-linejoin: round;
}

[id^="productAddToCartButton-"] #button.loading {
    --scale: .95;
    --span-y: -32px;
    --icon-r: 180deg;
    --fill: 1;
    --fill-d: .8s;
    --offset: 0;
    --offset-d: 1.73s;
}

@keyframes cart {
    12.5% {
        transform: translateX(-60px) rotate(-18deg);
    }
    25%, 45%, 55%, 75% {
        transform: none;
    }
    50% {
        transform: scale(.9);
    }
    87.5% {
        transform: translateX(70px) rotate(-18deg);
    }
    100% {
        transform: translateX(140px) rotate(-18deg);
    }
}
/* Responsive design for small and extra-small screens */

/* Extra small screens */
@media screen and (max-width: 470px) {
    #giftingHeading {
      font-size: 2em; /* Reduce font size for smaller screens */
      text-align: center;
    }
  
    #giftingContainer {
      padding-bottom: 15px;
    }
  
    #mainBoxContainerAllProducts {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
      /* margin-right: 30px; */
      grid-template-columns: repeat(1, 1fr);
    }
  
    [id^="productCard-"] {
      width: 100%;
      max-width: 300px; /* Adjust width for small screens */
      height: auto;
      padding: 10px;
      gap: 10px;
      border-radius: 10px;
    }
  
    [id^="productImageContainer-"] img {
      width: 100%;
      height: auto;
      border-radius: 10px;
    }
  
    [id^="productNameContainer-"] {
      font-size: 14px; /* Reduce font size */
    }
  
    [id^="productPriceContainer-"] {
      font-size: 14px; /* Reduce font size */
    }
  
    [id^="productAddToCartButton-"] #button {
      padding: 6px 20px;
      font-size: 14px;
    }
  
    /* Adjust shimmer card */
    .shimmer-product-card {
      width: 100%;
      max-width: 300px;
      height: auto;
      padding: 12px;
    }
  
    .shimmer-product-image {
      height: 120px; /* Adjust image height */
    }
  
    .shimmer-product-name,
    .shimmer-product-price,
    .shimmer-product-add-to-cart {
      width: 70%;
    }
  }
  
  /* Small screens */
  @media screen and (max-width: 768px) {
    #giftingHeading {
      font-size: 2.2em; /* Adjust font size for small screens */
      text-align: center;
    }
  
    #giftingContainer {
      padding-bottom: 20px;
    }
  
    #mainBoxContainerAllProducts {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 20px;
    }
  
    [id^="productCard-"] {
      width: 48%;
      max-width: 280px;
      height: auto;
      padding: 15px;
      gap: 15px;
      border-radius: 12px;
    }
  
    [id^="productImageContainer-"] img {
      height: 180px; /* Adjust image height for small screens */
    }
  
    [id^="productNameContainer-"] {
      font-size: 16px; /* Increase font size */
    }
  
    [id^="productPriceContainer-"] {
      font-size: 16px; /* Increase font size */
    }
  
    [id^="productAddToCartButton-"] #button {
      padding: 8px 25px;
      font-size: 16px;
    }
  
    /* Adjust shimmer card */
    .shimmer-product-card {
      width: 48%;
      max-width: 280px;
      height: 380px;
    }
  
    .shimmer-product-image {
      height: 180px;
    }
  }
  