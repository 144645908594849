#cancelOrderContainer {
  display: flex;
  justify-content: end;
}

#cancelOrderBtnDiv {
  padding: 10px;
}

#cancelOrderBtnDiv button {
  background-color: rgb(1, 181, 1);
  padding: 10px;
  border-radius: 20px;
  color: white;
}
