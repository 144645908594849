/* General shimmer effect */
.shimmer {
  animation: shimmer 1.5s infinite;
  background: linear-gradient(to right, #f0f0f0 8%, #e0e0e0 18%, #f0f0f0 33%);
  background-size: 1200px 100%;
}

@keyframes shimmer {
  0% {
    background-position: -1200px 0;
  }
  100% {
    background-position: 1200px 0;
  }
}

/* Shimmer styles for product cards */
.shimmer-product-card {
  width: 280px;
  height: 340px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 10px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.shimmer-product-image {
  width: 100%;
  height: 150px;
  border-radius: 8px;
  background-color: #f0f0f0;
}

.shimmer-product-name,
.shimmer-product-price,
.shimmer-product-add-to-cart {
  width: 80%;
  height: 20px;
  border-radius: 4px;
  background-color: #f0f0f0;
}

.shimmer-product-name {
  width: 60%;
}

.shimmer-product-price {
  width: 40%;
}

.shimmer-product-add-to-cart {
  width: 50%;
  height: 30px;
}

/* Main container for all products */
.mainBoxContainerAllProducts9 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* display: grid;
  grid-template-columns: repeat(4, 1fr); 3 columns for large screens */
  gap: 36px;
  padding: 10px;
  justify-content: center; /* Center the cards horizontally */
  align-items: center; /* Center the cards vertically */
}

/* Product Card Styling */
.productCard {
  width: 100%; /* Take full width within grid */
  max-width: 300px; /* Limit the card width */
  border: 1px solid #ddd;
  padding: 15px;
  text-align: center;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.productCard:hover {
  transform: translateY(-5px);
}

/* Product Image Styling */
.productImageContainer9 img {
  max-width: 180%;
  object-fit: cover;
  max-height: 300px;
  border-top-left-radius: 22px;
  border-top-right-radius: 22px;
  border-bottom-left-radius: -10px;
  border-bottom-right-radius: 0;
  transform: translateY(-10px) scale(0.8); /* Move image upward */
  left: 2px;
  position: relative;
}

/* Product Name Styling */
.productNameContainer9 {
  font-weight: bold;
  font-size: 16px;
  margin-top: -20px; /* Move the product name upwards */
  cursor: pointer;
}
.productNameContainer9 span {
  display: inline-block;
  transform: translateY(-15px); /* Move the text inside span upwards */
}

.productPriceContainer9 {
  font-weight: 600;
  font-size: 14px;
  color: green;
  margin-top: -10px; /* Move the product price upwards */
}
.productPriceContainer9 span {
  display: inline-block;
  transform: translateY(-2px); /* Move the text inside span upwards */
}

/* Add to Cart Button Styling */
.productAddToCartButton {
  margin-top: 10px;
}

.productAddToCartButton .button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  background-color: green;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: -5px;
}

.productAddToCartButton .button:hover {
  background-color: green;
}

/* Loading State */
.productAddToCartButton .button.loading {
  background-color: #ccc;
  cursor: not-allowed;
}

#mainDiscountContainerBanner{
  background-color: white;
  display: flex;
  justify-content: center;
  padding: 20px;
}

/* Responsive Design */

/* Large screens (1025px and above) */
@media (min-width: 1025px) {
  .mainBoxContainerAllProducts {
    grid-template-columns: repeat(3, 1fr); /* 3 columns for desktop view */
    gap: 20px;
  }
}

/* Tablets (768px to 1024px) */
@media (max-width: 1024px) {
  .mainBoxContainerAllProducts {
    grid-template-columns: repeat(2, 1fr); /* 2 columns for tablets */
    gap: 15px;
  }

  .productCard {
    max-width: 250px;
    padding: 10px;
  }

  .productAddToCartButton .button {
    font-size: 0.9rem;
    padding: 8px 12px;
  }
}

/* Mobile Devices (up to 767px) */
@media (max-width: 767px) {
  .mainBoxContainerAllProducts {
    grid-template-columns: 1fr; /* 1 column for mobile */
    gap: 10px;
  }

  .productCard {
    max-width: 100%;
    padding: 8px;
  }

  .productNameContainer {
    font-size: 1rem;
  }

  .productPriceContainer {
    font-size: 1rem;
  }

  .productAddToCartButton .button {
    font-size: 0.9rem;
    padding: 6px 10px;
  }
}

/* Extra Small Devices (up to 480px) */
@media (max-width: 480px) {
  .mainBoxContainerAllProducts {
    grid-template-columns: 1fr; /* Single column for very small devices */
    gap: 5px;
  }

  .productCard {
    max-width: 100%;
    padding: 5px;
  }

  .productNameContainer {
    font-size: 0.9rem;
  }

  .productPriceContainer {
    font-size: 0.9rem;
  }

  .productAddToCartButton .button {
    font-size: 0.8rem;
    padding: 5px 8px;
  }
}
