.myAccountContainer {
  padding: 30px;
  max-width: 900px; /* Max width for container */
  margin: 0 auto;
  display: block;
  justify-content: center; /* Align items to the start */
  align-items: center; /* Align items vertically */
  background: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  margin-bottom: 20px;
}


.profileDetailsContainer {
  flex: 2; /* Take more space for details */
  display: flex;
  flex-direction: column; /* Stack details vertically */
  gap: 20px; /* Space between profile fields */
}

.profileDetails {
  margin-bottom: 15px;
}

.profileDetails label {
  display: block;
  font-weight: bold;
  margin-bottom: 10px;
  font-size: 1rem;
  color: #333;
}

.profileDetails span,
.profileDetails input {
  width: 200px;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid #ddd;
  background-color: #fff;
  font-size: 1rem;
  transition: border-color 0.3s ease;
}

.profileDetails input:focus {
  border-color: #4caf50;
  outline: none;
}

.actions {
  margin-top: 20px;
  display: flex;
  gap: 20px;
}

.editButton,
.saveButton {
  padding: 12px 25px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.editButton:hover,
.saveButton:hover {
  background-color: #45a049;
  transform: scale(1.05);
}

.avatarContainer {
  flex: 1; /* Take up less space for avatar */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  margin-left: 40px; /* Space between avatar and details */
}

.avatar {
  width: 160px;
  height: 160px;
  border-radius: 50%;
  object-fit: cover;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
  transition: box-shadow 0.3s ease;
}

.avatar:hover {
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.3);
}

.uploadButton {
  padding: 10px 25px;
  background-color: #2196F3;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 12px;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.uploadButton:hover {
  background-color: #1976D2;
  transform: scale(1.05);
}

.myAccountContainer h2 {
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center; /* Align heading to the left */
  margin-bottom: 30px;
  background: linear-gradient(90deg, #4caf50, #2196F3);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.error {
  color: red;
  font-size: 0.85rem;
  margin-top: 5px;
}

.passwordContainer {
  position: relative;
}

.passwordToggleIcon {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  width: 24px;
  height: 24px;
  fill: #666;
}

input {
  transition: all 0.3s ease;
}

input:hover,
input:focus {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}
