/* DeliveryAddress.css */
.deliveryAddressContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    max-width: 600px;
    margin: 0 auto;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 80px;
    margin-bottom: 69px;
  }
  
  .deliveryAddressContainer h2 {
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
  }
  
  .deliveryAddressContainer form {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  
  .deliveryAddressContainer input {
    margin-bottom: 15px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 4px;
    width: calc(100% - 20px);
  }
  
  .deliveryAddressContainer input::placeholder {
    color: #888;
  }
  
  .deliveryAddressContainer button {
    padding: 15px;
    font-size: 16px;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .deliveryAddressContainer button:hover {
    background-color: #0056b3;
  }
  
  .deliveryAddressContainer button:active {
    background-color: #004494;
  }
  