.errorPageMainContainer{
    /* border: 2px solid red; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
    
}

.errorMsgContent{
    /* border: 2px solid green; */
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;
    margin-bottom: 100px;
}

.errorMsgContent h2{
    color: rgb(192, 17, 17);
    font-size: 84px;
}
.errorMsgContent p{
    color: rgb(193, 0, 0);
    font-size: 18px;
    font-weight: 500;
}

.errorBackToHomeBtn{
    /* border: 2px solid blue; */
    margin-bottom: 100px;
}

.errorBackToHomeBtn button{
    background-color: rgb(167, 19, 19);
    color: white;
    padding: 6px 32px 6px 32px;
    border-radius: 16px;
    font-weight: 500;
}