/* General shimmer effect */
.shimmer {
  animation: shimmer 1.5s infinite;
  background: linear-gradient(to right, #f0f0f0 8%, #e0e0e0 18%, #f0f0f0 33%);
  background-size: 1200px 100%;
}

@keyframes shimmer {
  0% {
    background-position: -1200px 0;
  }
  100% {
    background-position: 1200px 0;
  }
}

/* Shimmer styles for product cards */
.shimmer-product-card {
  width: 280px;
  height: 340px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 10px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.shimmer-product-image {
  width: 100%;
  height: 150px;
  border-radius: 8px;
  background-color: #f0f0f0;
}

.shimmer-product-name,
.shimmer-product-price,
.shimmer-product-add-to-cart {
  width: 80%;
  height: 20px;
  border-radius: 4px;
  background-color: #f0f0f0;
}

.shimmer-product-name {
  width: 60%;
}

.shimmer-product-price {
  width: 40%;
}

.shimmer-product-add-to-cart {
  width: 50%;
  height: 30px;
}

.newLaunchHeading {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.newLaunch {
  font-size: 2.5em;
  font-weight: bold;
  color: #4caf50;
  text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.7);
}

.newLaunchContainer {
  padding-bottom: 20px;
}

.mainBoxContainerAllProducts12 {
  display: flex;
  flex-wrap: wrap;
  gap: 79px;
  justify-content: center;
  margin-top: 20px;
}

.productCard1 {
  /* border: 1px dashed rgb(206, 79, 79); */
  width: 280px;
  height: 380px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  border-radius: 22px;
  background-color: #f5f5f5;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.card-1 {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.card-1:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.productImageContainer2 {
  /* border: 2px solid rgb(232, 51, 81); */
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  position: relative;
  overflow: hidden;
}

.productImageContainer2 img {
  width: 100%;
  height: 100%;
  margin-top: 20px;
}

.productImageContainer2 img {
  border-radius: 22px;
}

.productNameContainer2 {
  font-weight: bold;
  margin-top: 4px;
}
.productNameContainer2 span {
  display: inline-block;
  transform: translateY(-1px); /* Move the text inside span upwards */
}

.productPriceContainer2 {
  font-weight: 600;
  color: green;
}
.productPriceContainer2 span {
  display: inline-block;
  transform: translateY(-2px); /* Move the text inside span upwards */
}
.productAddToCartButton1 {
  margin-top: -10px; /* Adjust the button's position as needed */
  cursor: pointer;
}

.productAddToCartButton1 button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.productAddToCartButton1 .cart {
  margin-left: 10px;
}

/* Add to Cart Code Animation */
.button {
  --background: green;
  --text: #fff;
  --cart: #fff;
  --tick: var(--background);
  position: relative;
  margin-bottom: 10px;
  border: none;
  background: none;
  padding: 8px 28px;
  border-radius: 8px;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  overflow: hidden;
  cursor: pointer;
  text-align: center;
  min-width: 144px;
  color: var(--text);
  background: var(--background);
  transform: scale(var(--scale, 1));
  transition: transform 0.4s cubic-bezier(0.36, 1.01, 0.32, 1.27);
  &:active {
    --scale: 0.95;
  }
  span {
    font-size: 14px;
    font-weight: 500;
    display: block;
    position: relative;
    padding-left: 24px;
    margin-left: -8px;
    line-height: 26px;
    transform: translateY(var(--span-y, 0));
    transition: transform 0.7s ease;
    &:before,
    &:after {
      content: "";
      width: var(--w, 2px);
      height: var(--h, 14px);
      border-radius: 1px;
      position: absolute;
      left: var(--l, 8px);
      top: var(--t, 6px);
      background: currentColor;
      transform: scale(0.75) rotate(var(--icon-r, 0deg))
        translateY(var(--icon-y, 0));
      transition: transform 0.65s ease 0.05s;
    }
    &:after {
      --w: 14px;
      --h: 2px;
      --l: 2px;
      --t: 12px;
    }
  }
  .cart {
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -13px 0 0 -18px;
    transform-origin: 12px 23px;
    transform: translateX(-120px) rotate(-18deg);
    &:before,
    &:after {
      content: "";
      position: absolute;
    }
    &:before {
      width: 6px;
      height: 6px;
      border-radius: 50%;
      box-shadow: inset 0 0 0 2px var(--cart);
      bottom: 0;
      left: 9px;
      filter: drop-shadow(11px 0 0 var(--cart));
    }
    &:after {
      width: 16px;
      height: 9px;
      background: var(--cart);
      left: 9px;
      bottom: 7px;
      transform-origin: 50% 100%;
      transform: perspective(4px) rotateX(-6deg) scaleY(var(--fill, 0));
      transition: transform 1.2s ease var(--fill-d);
    }
    svg {
      z-index: 1;
      width: 36px;
      height: 26px;
      display: block;
      position: relative;
      fill: none;
      stroke: var(--cart);
      stroke-width: 2px;
      stroke-linecap: round;
      stroke-linejoin: round;
      polyline {
        &:last-child {
          stroke: var(--tick);
          stroke-dasharray: 10px;
          stroke-dashoffset: var(--offset, 10px);
          transition: stroke-dashoffset 0.4s ease var(--offset-d);
        }
      }
    }
  }
  &.loading {
    --scale: 0.95;
    --span-y: -32px;
    --icon-r: 180deg;
    --fill: 1;
    --fill-d: 0.8s;
    --offset: 0;
    --offset-d: 1.73s;
    .cart {
      animation: cart 3.4s linear forwards 0.2s;
    }
  }
}

@keyframes cart {
  12.5% {
    transform: translateX(-60px) rotate(-18deg);
  }
  25%,
  45%,
  55%,
  75% {
    transform: none;
  }
  50% {
    transform: scale(0.9);
  }
  44%,
  56% {
    transform-origin: 12px 23px;
  }
  45%,
  55% {
    transform-origin: 50% 50%;
  }
  87.5% {
    transform: translateX(70px) rotate(-18deg);
  }
  100% {
    transform: translateX(140px) rotate(-18deg);
  }
}
